<template>
	<div class="slot-toolbar-item" @click="action">
		<div class="item__icon">
			<img :src="require('@/assets/' + iconFileName)" height="22">
		</div>
		<div class="item__title">	
			{{title}}
		</div>
	</div>
</template>

<script>
export default {
	name: 'SlotToolBarItem',

	props: {
		title: {
			type: String,
			required: true,
		},
		iconFileName: {
			type: String,
			required: true,
		},
		action: {
			type: Function,
			required: true,
		}
	},

	
};
</script>

<style scoped>
.slot-toolbar-item{
	height: 50px;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	margin: 5px 0;
}

.slot-toolbar-item > * {
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: space-evenly;
}

</style>