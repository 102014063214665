<template>
	<div class="slot-toolbar-container">
		<transition name="context">
			<div class="context-header" v-if="isOpen">
				<div class="header__closer" @click="close">
					<img src="@/assets/close-rounded.svg" height="22">
				</div>
				<div class="header__title" v-if="selectedSlots.length > 0">
					Selected {{selectedSlots.length}}
				</div>
				<div class="header__title" v-else>
					Selected slots
				</div>
			</div>	
		</transition>

		<!-- <transition >
			<SlotToolBarMakeRegular 
				v-if="isOpenMakeRegular"
				:selectedSlots="selectedSlots"/>
		</transition> -->
	
		<transition name="toolbar">
			<div class="slot-toolbar" v-if="isOpen">
				<SlotToolBarItem 
					v-for="button, index in getDefaultItems()"
					:key="`slot-toolbar-item-${index}`"
					:title="button.title"
					:iconFileName="button.iconFileName"
					:action="button.action"
				/>	
			</div>	
		</transition>
	</div>
</template>

<script>
import { isEqualDate } from '../../scripts/date';
import SlotToolBarItem from "./SlotToolBarItem.vue"

export default {
	name: 'SlotToolBar',
	components: {
		SlotToolBarItem,
	},
	computed: {
		selectedSlots(){
			return this.$store.getters.tableSlotsToolBar
		},
		isOpen(){
			return this.selectedSlots != undefined
		}
	},

	data(){
		return {
		}
	},

	methods: {
		close(){
			this.$store.dispatch('update-slot-toolbar')
		},
		setDateToClone(){
			// if (!this.selectedSlots)
			// 	return

			// let selectedDates = []

			// this.selectedSlots.forEach(slot => {
			// 	let dateIsExists = selectedDates.find(date => {
			// 		return isEqualDate(date, slot.period.start.date)
			// 	})
			// 	if (!dateIsExists){
			// 		selectedDates.push(date)
			// 	}
			// });
			// if (selectedDates.length != 1)
			// 	return
			// this.$store.dispatch('update-date-to-clone', {date: selectedDates[0]})
		},
		getDefaultItems(){
			return  [
				{
					title: "Share",
					iconFileName: "share-outline.svg",
					action: () => console.log('share! ')
				},
				{
					title: "Clone",
					iconFileName: "content-copy-outline-rounded.svg",
					action: () => console.log('clone! '),
				},
				{
					title: "Delete",
					iconFileName: "delete-outline-rounded.svg",
					action: () => console.log('delete')
				}
			]
		},
	}
};
</script>

<style scoped>

*{
	--animation-duration: .25s;
}

.context-header {
	display: flex;
	flex-direction: row;
	gap: 10px;

    background: white;
    height: 50px;
    width: 100%;
    position: fixed;

    z-index: 6;
    border-bottom: 1px solid gray;
    transition: bottom var(--animation-duration) ease-in-out;
    top: 32.4px;
}
.slot-toolbar {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;

    background: white;
    height: 50px;
    width: 100%;
    position: fixed;

    z-index: 6;
    border-top: 1px solid gray;
    transition: bottom var(--animation-duration) ease-in-out;
    bottom: 0;
}

.toolbar-leave-active {
  animation: toolbar var(--animation-duration) ease-out;
}
.toolbar-enter-active {
  animation: toolbar var(--animation-duration) ease-in reverse;
}
@keyframes toolbar {
	from {
		opacity: 1;
		bottom: 0px;
		transform: bottom var(--animation-duration) ease-in-out;
	}
	to {
		opacity: 0;
		bottom: -15px;
		transform: bottom var(--animation-duration) ease-in-out;
	}
}

.context-leave-active {
  animation: context var(--animation-duration) ease-out;
}
.context-enter-active {
  animation: context var(--animation-duration) ease-in reverse;
}
@keyframes context {
	from {
		opacity: 1;
		top: 32.4px;
		transform: bottom var(--animation-duration) ease-in-out;
	}
	to {
		opacity: 0;
		top: 15px;
		transform: bottom var(--animation-duration) ease-in-out;
	}
}

.context-header > * {
	display: flex;
	align-items: center;
}

.header__closer {
	justify-content: center;
	width: 50px;
}

.backdrop{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 4;
	background: rgba(94, 92, 92, 0.25);
}
</style>